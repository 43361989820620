<template>
    <div class="shanty-overview">
        <div class="top-row">
                    <div @click="$router.go(-1)" class="back-arrow"><Left/></div>
                    <div class="title">{{shantyData.name}}</div>
                    <span style="visibility:hidden"><Left/></span>
                </div>
        <div class="version-wrapper">
            <div class="version-label">Versions</div>
            <div class="versions">
                <router-link class="link-card" :to="'/shanty/'+filename+'/'+id"  v-for="(v, id) in shantyData.versions" v-bind:key="v.credit">
                    <div class="version-card">
                        <div class="card-name">{{v.name}}</div>
                        <div class="card-credit">{{v.credit}}</div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Left from 'vue-material-design-icons/ChevronLeft.vue';

export default {
    props: ['filename'],
    components: {
        Left
    },
    data: function () {
        return {
            shantyData: undefined
        }
    },
    created() {
        this.shantyData = require('@/assets/shanties/shanties/' + this.filename + '.json');
    }
}
</script>

<style lang="scss" scoped>
    .shanty-overview {
        .top-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 1rem;

            .back-arrow {
                color: inherit;
                text-decoration: none;
            }
            .title {
                font-size: 1.5rem;
                text-align: center;
                padding: 1.5rem 16px .8rem 16px;
                font-weight: 300;
            }
        }
        .version-wrapper {
            margin: 1.5rem;

            .version-label {
                color: #00f6ff;
            }

            .versions {
                margin: 1rem 0;

                .link-card {
                    color: inherit;
                    text-decoration: none;
                }

                .version-card {
                    background: #25004b;
                    border: 1px solid #00f6ff;
                    margin: 1rem;
                    padding: 1rem;
                    border-radius: 5px;

                    .card-name {
                        font-size: 1.2rem;
                        padding-bottom: 0.5rem;
                    }
                    .card-credit {
                        color: #00f6ff;
                    }
                }
            }
        }
    }
</style>